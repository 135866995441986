import './Layout.scss';

import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/layout/SplitLayout';
import Sidebar from 'components/layout/Sidebar';

interface Props {
  children: React.ReactNode;
}

/**
 * Layout for all pages in the 'about' section including a sidebar with
 * secondary navigation and data about the organization.
 *
 * This is not included in the 'layouts' folder since layouts are being removed
 * in the next version of gatsby and replaced with regular components with no
 * special behavior.
 */
const AboutLayout: React.FC<Props> = (props) => {
  const {
    sidebar: { html }
  } = useStaticQuery(graphql`
    query {
      sidebar: markdownRemark(
        fileAbsolutePath: { glob: "**/about/sidebar.md" }
      ) {
        html
      }
    }
  `);

  return (
    <Layout
      sidebar={
        <Sidebar>
          <h4 className="AboutLayout-sidebar-label no-print">About</h4>
          <nav className="AboutLayout-sidebar-nav no-print">
            <ul className="AboutLayout-sidebar-nav-list">
              <li className="AboutLayout-sidebar-nav-list-item">
                <Link
                  to="/about/"
                  className="AboutLayout-sidebar-nav-link"
                  activeClassName="active"
                >
                  About SEL
                </Link>
              </li>
              <li className="AboutLayout-sidebar-nav-list-item">
                <Link
                  to="/work/"
                  className="AboutLayout-sidebar-nav-link"
                  activeClassName="active"
                >
                  Our Work
                </Link>
              </li>
              <li className="AboutLayout-sidebar-nav-list-item">
                <Link
                  to="/team/"
                  className="AboutLayout-sidebar-nav-link"
                  activeClassName="active"
                >
                  Our Team
                </Link>
              </li>
              <li className="AboutLayout-sidebar-nav-list-item">
                <Link
                  to="/methods/"
                  className="AboutLayout-sidebar-nav-link"
                  activeClassName="active"
                >
                  Our Methods
                </Link>
              </li>
            </ul>
          </nav>

          <div className="AboutLayout-sidebar-markdown">
            <Core.Markdown>{html}</Core.Markdown>
          </div>
        </Sidebar>
      }
    >
      <div className="AboutLayout">
        <div className="AboutLayout-content">{props.children}</div>
      </div>
    </Layout>
  );
};

export default AboutLayout;
