import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/about/Layout';

const WorkPage: React.FC = () => {
  const {
    sidebar: { html }
  } = useStaticQuery(graphql`
    query {
      sidebar: markdownRemark(
        fileAbsolutePath: { glob: "**/about/our-work.md" }
      ) {
        html
      }
    }
  `);

  return (
    <Layout>
      <Core.Markdown>{html}</Core.Markdown>
    </Layout>
  );
};

export default WorkPage;
